import $ from "jquery";

$(function() {
  /* ========================================================================
   * Wieckstrap: dropdown.js v3.1.0
   * http://getbootstrap.com/javascript/#dropdowns
   * ========================================================================
   * Copyright 2011-2014 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
   * ======================================================================== */

  // DROPDOWN CLASS DEFINITION
  // =========================

  const backdrop = '.dropdown-backdrop';
  const toggle   = '[data-toggle=wieck-dropdown], [data-toggle=wieck-dropdown-submenu]';
  const WieckDropdown = function (element) {
      $(element).on('click.bs.wieckDropdown', this.toggle);
  };

  WieckDropdown.prototype.toggle = function (e) {
    const $this = $(this);

    if ($this.is('.disabled, :disabled')) return;

    const $parent  = getParent($this);
    const isActive = $parent.hasClass('open');

    const isSubmenu = $this.data('toggle') === 'wieck-dropdown-submenu';

    if ($this.parents('.open').length === 0) {
      clearMenus(e);
    } else {
      $parent.siblings('.open').removeClass('open');
    }

    if (window.matchMedia("(max-width: 1200px)").matches) {
      if (!isActive) {
        if ('ontouchstart' in document.documentElement && !$parent.closest('.navbar-nav').length) {
          // if mobile we use a backdrop because click events don't delegate
          $('<div class="dropdown-backdrop"/>').insertAfter($(this)).on('click', clearMenus);
        }
        $parent.addClass('open');
        $this.focus();
      } else {
        window.location = $this.attr("href");
      }
    } else {
      if (!isActive && !isSubmenu) {
        if ('ontouchstart' in document.documentElement && !$parent.closest('.navbar-nav').length) {
          // if mobile we use a backdrop because click events don't delegate
          $('<div class="dropdown-backdrop"/>').insertAfter($(this)).on('click', clearMenus);
        }
        $parent.addClass('open');
        $this.focus();
      } else {
        window.location = $this.attr("href");
      }
    }

    return false;
  };

  WieckDropdown.prototype.keydown = function (e) {
      if (!/(38|40|27)/.test(e.keyCode)) return;

      const $this = $(this);

      e.preventDefault();
      e.stopPropagation();

      if ($this.is('.disabled, :disabled')) return;

      const $parent  = getParent($this);
      const isActive = $parent.hasClass('open');

      if (!isActive || (isActive && e.keyCode == 27)) {
          if (e.which == 27) $parent.find(toggle).focus();
          return $this.click();
      }

      const desc = ' li:not(.divider):visible a';
      const $items = $parent.find('[role=menu]' + desc + ', [role=listbox]' + desc);

      if (!$items.length) return;

      var index = $items.index($items.filter(':focus'));

      if (e.keyCode == 38 && index > 0)                 index--;                        // up
      if (e.keyCode == 40 && index < $items.length - 1) index++ ;                       // down
      if (!~index)                                      index = 0;

      $items.eq(index).focus();
  };

  function clearMenus(e) {
      $(backdrop).remove();
      $(toggle).each(function () {
          const $parent = getParent($(this));
          const relatedTarget = { relatedTarget: this };
          if (!$parent.hasClass('open')) return;
          $parent.trigger(e = $.Event('hide.bs.wieckDropdown', relatedTarget));
          if (e.isDefaultPrevented()) return;

          $parent.removeClass('open').trigger('hidden.bs.wieckDropdown', relatedTarget);
      });
  }

  function getParent($this) {
      let selector = $this.attr('data-target');

      if (!selector) {
          selector = $this.attr('href');
          selector = selector && /#[A-Za-z]/.test(selector) && selector.replace(/.*(?=#[^\s]*$)/, ''); //strip for ie7
      }

      const $parent = selector && $(selector);

      return $parent && $parent.length ? $parent : $this.parent();
  }


  // DROPDOWN PLUGIN DEFINITION
  // ==========================

  var old = $.fn.dropdown;

  $.fn.wieckDropdown = function (option) {
      return this.each(function () {
          var $this = $(this);
          var data  = $this.data('bs.wieckDropdown');

          if (!data) $this.data('bs.wieckDropdown', (data = new WieckDropdown(this)));
          if (typeof option == 'string') data[option].call($this);
      });
  };

  $.fn.wieckDropdown.Constructor = WieckDropdown;


  // DROPDOWN NO CONFLICT
  // ====================

  $.fn.wieckDropdown.noConflict = function () {
      $.fn.wieckDropdown = old;
      return this;
  };


  // APPLY TO STANDARD DROPDOWN ELEMENTS
  // ===================================

  $(document)
      .on('click.bs.wieckDropdown.data-api', clearMenus)
      .on('click.bs.wieckDropdown.data-api', '.dropdown form', function (e) { e.stopPropagation(); })
      .on('click.bs.wieckDropdown.data-api', toggle, WieckDropdown.prototype.toggle)
      .on('keydown.bs.wieckDropdown.data-api', toggle + ', [role=menu], [role=listbox]', WieckDropdown.prototype.keydown);

});